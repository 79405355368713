<template>
  <v-container>
    <v-row
      align="start"
      justify="start"
    >
      <v-col class="checkout-content">
        <v-tabs
          v-model="step"
          align-with-title
          hide-slider
        >
          <v-tab
            v-for="(stepObj, index) in checkoutSteps"
            :key="index"
            :disabled="!stepObj.allowed"
            :class="'button-'.concat(stepObj.label.toLowerCase().replace(/[^a-zA-Z ]/g, '').replace(/[ ]/g, '-'))"
          >
            <v-icon
              class="step-num"
              color="primary"
            >
              mdi-numeric-{{ index + 1 }}-circle-outline
            </v-icon>
            {{ stepObj.label }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="step">
          <v-tab-item
            v-for="(stepObj, index) in checkoutSteps"
            :key="index"
          >
            <v-card flat>
              <transition
                v-if="stepObj.label === 'Shipping Information'"
                name="simple-slide-03"
                appear
              >
                <order-form
                  ref="address-form"
                  :callbacks="getCallbacks()"
                  :oid="oid"
                  submit-text="Next"
                />
              </transition>
              <transition
                v-if="stepObj.label === 'Payment'"
                name="simple-slide-03"
                appear
              >
                <v-container v-if="offer">
                  <v-card class="step-title">
                    <v-card-title>{{ $t('checkout-step-2-title') }}</v-card-title>
                  </v-card>
                  <v-container>
                    <div class="order-summary">
                      <v-row class="sections-row">
                        <v-col>
                          <v-card
                            class="summary-section billing-details"
                          >
                            <v-card-title>{{ $t('billing-details') }}</v-card-title>
                            <v-card-text>
                              <div
                                v-for="(propertyValue, propertyName) in summaryData('billing-details')"
                                v-bind:key="propertyName"
                                class="section-property"
                              >
                                <span class="label">{{ propertyName }}: </span>
                                <span class="value">{{ propertyValue }}</span>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col>
                          <v-card
                            class="summary-section offer-items"
                          >
                            <v-card-title>{{ $t('offer-items') }}</v-card-title>
                            <v-card-text>
                              <div
                                v-for="(propertyValue, propertyName, propertyQuantity) in summaryData('offer-items')"
                                v-bind:key="propertyName"
                                class="section-property"
                              >
                                <div
                                v-for="(propertyValue, propertyName) in propertyValue"
                                v-bind:key="propertyName"
                                >
                                  <span class="label">{{ propertyName }}: </span>
                                  <span class="value">{{ propertyValue }}</span>
                                  <span class="value">{{ propertyQuantity }}</span>
                                </div>
                                <br />
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row class="sections-row">
                        <v-col>
                          <v-card
                            class="summary-section pickup-address"
                          >
                            <v-card-title>{{ $t('pickup-address') }}</v-card-title>
                            <v-card-text>
                              <div
                                v-for="(propertyValue, propertyName) in summaryData('pickup-address')"
                                v-bind:key="propertyName"
                                class="section-property"
                              >
                                <span class="label">{{ propertyName }}: </span>
                                <span class="value">{{ propertyValue }}</span>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col>
                          <v-card
                            class="summary-section delivery-address"
                          >
                            <v-card-title>{{ $t('delivery-address') }}</v-card-title>
                            <v-card-text>
                              <div
                                v-for="(propertyValue, propertyName) in summaryData('delivery-address')"
                                v-bind:key="propertyName"
                                class="section-property"
                              >
                                <span class="label">{{ propertyName }}: </span>
                                <span class="value">{{ propertyValue }}</span>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row
                        class="price-wrapper"
                      >
                        <v-col>
                          <v-card>
                            <v-card-text>
                              <!-- <span class="label">Total Cost:</span> <span class="price">&euro;{{ offer.Total }} </span> <span class="vat">+VAT(24%)</span> -->
                              <div class="price-wrapper">
                                <span class="label">{{ $t('cost') }}: </span>
                                <span class="price">{{ (Math.round((offer.Total + Number.EPSILON) * 100) / 100).toFixed(2) }}&euro; </span>
                              </div>
                              <div
                                class="price-wrapper"
                                style="padding-top: 5px;"
                                >
                                <span class="label">{{ $t('vat-empty') }}({{ Math.round((Math.round((clientOffer.Vat + Number.EPSILON) * 100) / 100)/(Math.round((clientOffer.Total + Number.EPSILON) * 100) / 100)*100) }}%): </span>
                                <span class="price">{{ (Math.round((clientOffer.Vat + Number.EPSILON) * 100) / 100).toFixed(2) }}&euro; </span>
                              </div>
                              <div
                                class="price-wrapper"
                                style="padding-top: 5px;"
                                >
                                <span class="label">{{ $t('total-cost') }}: </span>
                                <span class="price">{{ (Math.round((clientOffer.TotalWithVat + Number.EPSILON) * 100) / 100).toFixed(2) }}&euro; </span>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                  </v-container>
                  <v-container>
                    <v-row
                      align="center"
                      class="payment-section"
                    >
                      <v-col>
                        <div class="select-payment-method-text">{{ $t('select-payment-method') }}</div>
                      </v-col>
                      <v-col>
                        <v-card class="payment-methods-card">
                          <v-card-text>
                            <v-form
                              ref="payment-method-form"
                              v-model="paymentMethodForm"
                              lazy-validation
                            >
                              <v-radio-group
                                v-model="paymentMethod"
                                class="payment-methods-list"
                                :rules="getRules(null, true, $t('payment-method-required'))"
                                required
                              >
                                <template
                                  v-for="(paymentMethod, index) in paymentMethodsList"
                                >
                                  <v-radio
                                    v-if="paymentMethod.enabled"
                                    v-bind:key="index"
                                    :value="paymentMethod.code"
                                  >
                                    <template v-slot:label>
                                      <div class="payment-method-row bank-transfer">
                                        <div class="payment-method-title">{{ $t(paymentMethod.label) }}</div>
                                        <div
                                          v-if="paymentMethod.text"
                                          class="payment-method-description"
                                          v-html="paymentMethod.text"
                                        />
                                      </div>
                                    </template>
                                  </v-radio>
                                </template>
                              </v-radio-group>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row class="accept-terms-row">
                      <v-col />
                      <v-col>
                        <v-form
                          ref="terms-and-conditions-form"
                          class="accept-terms-and-conditions"
                        >
                          <v-checkbox
                            v-model="acceptTermsAndConditions"
                            :rules="getRules(null, true, $t('require-to-accept-terms-and-conditions'))"
                            fluid
                          >
                            <template v-slot:label>
                              <span
                                class="checkbox-text"
                              >
                                <span
                                  class="accept-text"
                                >{{ $t('i-accept') }} </span>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <a
                                      href="/terms-and-conditions"
                                      @click.stop
                                      v-on="on"
                                      target="_blank"
                                    >{{ $t('terms-and-conditions-verbal') }}</a>
                                  </template>
                                  {{ $t('terms-and-conditions') }}
                                </v-tooltip>
                              </span>
                            </template>
                          </v-checkbox>
                        </v-form>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-card>
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="primary"
                              class="form-btn"
                              @click="submitOrder(['payment-method-form','terms-and-conditions-form'])"
                            >
                              Submit Order
                            </v-btn>
                            <slot name="button-slot" />
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-container>
              </transition>
              <loader v-show="loader" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import OrderForm from '@/components/Forms/OrderForm'
  import { dataMappers, authComputed, helperFunctions } from '@/store/helpers'
  import { getRules } from '@/store/formValidationRules'
  import { loadCheckoutScript } from '../../store/modules/api-payment'

  export default {
    name: 'MyOrders',
    components: {
      OrderForm,
    },
    props: {
      oid: {
        type: [Number, String],
        default: -1,
      },
    },
    data: () => ({
      loader: false,
      addressModal: false,
      newAddressIsDestination: false,
      acceptTermsAndConditions: false,
      paymentMethodForm: false,
      paymentMethod: Vue.prototype.$defaultPaymentMethod,
      offer: null,
      token: null,
      checkoutSteps: [
        {
          label: 'Shipping Information',
          allowed: true,
        },
        {
          label: 'Payment',
          allowed: false,
        },
      ],
      step: 0,
    }),
    computed: {
      ...authComputed,
      ...mapGetters('orders', ['getOfferByOid']),
      ...mapGetters('apiGeneric', ['getCountryDisplayNameByOid', 'getPackagingTypeNameById']),
      paymentMethodsList: () => {
        return Vue.prototype.$paymentMethods
      },
      clientOffer: function () {
        return this.getOfferByOid(this.oid)
      },
    },
    created () {
      if (this.oid) {
        const offerObj = this.getOfferByOid(this.oid)
        if (!offerObj) {
          this.$router.push({ name: 'NotFound' })
        }
        if (!offerObj) {
          this.$store.dispatch('notificationAlerts/addNotification', {
            text: 'Offer with id ' + this.oid + ' does not exist',
            type: 'error',
          },
          )
          this.$router.push({ name: 'Account/MyOffers' })
        }

        if (offerObj.IsExpired) {
          this.$store.dispatch('notificationAlerts/addNotification', {
            text: 'Offer with id ' + this.oid + ' is expired and cannot be turned into order.',
            type: 'warning',
          },
          )
          this.$router.push({ name: 'Account/MyOffers' })
        }
        console.log('this.$route', this.$route)
        // if user cancels payment, then the redirection will have this hash int the ur. if this condition is met, then it is a redirection from the bank payment page
        if (this.$route.hash === '#__hc-action-cancel') {
          console.log('offerObj', offerObj)
          this.$store.commit('orders/SUCCESS_PAGE_ALLOW_ACCESS_ADD_OID', offerObj.Order.Oid)
          this.$router.push({
            name: 'Order/Success',
            params: {
              oid: offerObj.Order.Oid,
              failure: true,
            },
          })
          return
        }

        if (offerObj.HasBeenTurnedToOrder) {
          this.$store.dispatch('notificationAlerts/addNotification', {
            text: 'Offer with id ' + this.oid + ' has already been turned into order.',
            type: 'warning',
          },
          )
          this.$router.push({ name: 'Account/MyOffers' })
        }
      } else {
        this.$router.push({ name: 'NotFound' })
      }
    },
    methods: {
      ...mapGetters('customer', ['getCustomerName', 'getCustomerEmail']),
      ...mapGetters('apiGeneric', ['getCountries']),
      ...mapActions('apiPayment', ['action_payOrderWithCard', 'action_getOrderID']),
      async submitOrder (formIds = null) {
        // formId can be any form reference that need validation before order placement
        var canSubmit = true
        const self = this
        // validate all forms before submit
        if (Array.isArray(formIds)) {
          for (let i = 0; i < formIds.length; i++) {
            if (!this.$refs[formIds[i]][0].validate()) {
              canSubmit = false
            }
          }
        } else if (formIds && !this.$refs[formIds][0].validate()) {
          canSubmit = false
        }

        if (canSubmit) {
          // todo get sessionID for payment
          this.loader = true

          const saveOrderPromise = this.sendOrderRequest()

          saveOrderPromise.then(function (responseData) {
            if (responseData) {
              const orderData = responseData.data
              if (responseData.status !== 200) {
                self.$store.dispatch('notificationAlerts/addNotification', {
                  text: orderData.CalculatedCostIssues,
                  type: 'error',
                },
                )
              } else {
                // fetch orders locally again
                self.$store.dispatch('orders/action_getOfferById', { offerId: orderData.Offer.Oid })
                self.$store.dispatch('customer/action_getCustomerAddresses')
                self.$store.commit('orders/SUCCESS_PAGE_ALLOW_ACCESS_ADD_OID', orderData.Oid)
                self.$store.dispatch('orders/action_getOrderById', { orderId: orderData.Oid }).then((response) => {
                  if (self.paymentMethod === 'ByCard') {
                    self.action_payOrderWithCard({
                      orderID: orderData.Oid,
                    })
                  } else {
                    self.$router.push({
                      name: 'Order/Success',
                      params: { oid: orderData.Oid },
                    })
                  }
                })
              }
            }
          })
            .catch(() => {
              this.$store.commit('orders/ERROR_PAGE_INCREMENT_COUNTER')
              self.$router.push({ name: 'Failure' })
            })
            .finally(function () {
              self.loader = false
            })
        }
      },
      sendOrderRequest: function () {
        const addressFrom = this.offer.addressFrom
        const addressTo = this.offer.addressTo
        const requestPayload = {
          Commit: true,
          SaveAddressFrom: this.offer.saveFromAddress,
          SaveAddressTo: this.offer.saveToAddress,
          Offer: {
            Oid: this.oid ?? null,
          },
          ClientName: this.offer.Customer.CompanyName,
          ClientEmail: this.offer.Customer.email,
          ClientCountry: {
            Oid: this.offer.Customer.Country,
          },
          ClientTelephone: this.offer.Customer.telephone,
          ClientVatNo: this.offer.Customer.VatNumber,
          ClientVatArea: {
            Oid: this.offer.Customer.VatArea,
          },
          ClientTaxAuthority: this.offer.Customer.TaxActivity,
          ClientDescription: this.offer.Customer.CompanyDescription,
          PickUpReference: this.offer.PickUpReference,
          OnBehalfOf: this.offer.OnBehalfOf,
          ContentType: this.offer.ContentType,
          PickUpDate: helperFunctions.encodeDateForApi(this.offer.PickUpDate),
          AddressFrom: dataMappers.orderFromAddressToApiMapper(addressFrom),
          AddressTo: dataMappers.orderFromAddressToApiMapper(addressTo),
          Items: [],
          PaymentType: this.paymentMethod,
          ReCaptchaResponse: this.token,
        }

        requestPayload.AddressFrom.CompanyName = addressFrom.CompanyName
        requestPayload.AddressTo.CompanyName = addressTo.CompanyName
        const alterdata = this.getOfferByOid(this.offer.oid)

        for (let i = 0; i < this.offer.items.length; i++) {
          requestPayload.Items.push({
            PackagingType: {
              Oid: this.offer.items[i].packagingType,
            },
            Width: this.offer.items[i].width,
            Height: this.offer.items[i].height,
            Length: this.offer.items[i].length,
            Weight: this.offer.items[i].weight,
            Quantity: alterdata.Items[i].Quantity,
          })
        }

        return this.$store.dispatch('orders/createOrder', requestPayload)
      },
      getCallbacks: function () {
        const self = this
        return {
          submit (offer, token) {
            self.offer = offer
            self.token = token
            self.nextStep()
          },
        }
      },
      nextStep: function (formId = null) {
        if (!formId || this.$refs[formId][0].validate()) {
          this.step++
          this.checkoutSteps[this.step].allowed = true
        }
      },
      summaryData: function (section) {
        var data = {}
        switch (section) {
          case 'billing-details':
            data = {
              'Company Name': this.offer.Customer.CompanyName,
              'Company Description': this.offer.Customer.CompanyDescription,
              'Email to receive invoice': this.offer.Customer.email,
              'Vat Number': this.offer.Customer.VatNumber,
              Tax: this.offer.Customer.TaxActivity,
              Telephone: this.offer.Customer.TaxActivity,
              Country: this.getCountryDisplayNameByOid(this.offer.Customer.Country),
            }
            break
          case 'offer-items':
            data = []
            for (let i = 0; i < this.offer.items.length; i++) {
              const currentOffer = this.getOfferByOid(this.offer.oid)
              const itemTitle = this.offer.items[i].title
              const newItem = {
                Title: this.getPackagingTypeNameById(this.offer.items[i].packagingType),
                Quantity: currentOffer.Items[i].Quantity,
              }
              data.push(newItem)
            }
            break
          case 'pickup-address':
            data = {
              'Company Name From': this.offer.addressFrom.CompanyName,
              'Address From': this.offer.addressFrom.AddressDetails,
              'Postal Code': this.offer.addressFrom.PostCode,
              'Country From': this.getCountryDisplayNameByOid(this.offer.addressFrom.Country),
              'Contact Person': this.offer.addressFrom.ContactPerson,
              Email: this.offer.addressFrom.Email,
              'Telephone Number': this.offer.addressFrom.Telephone,
              'Pickup Reference': this.offer.PickUpReference,
              'Content Type': this.offer.ContentType,
              'On behalf of': this.offer.OnBehalfOf,
              'Pickup Date': this.offer.PickUpDate,
            }
            break
          case 'delivery-address':
            data = {
              'Company Name To': this.offer.addressTo.CompanyName,
              'Address To': this.offer.addressTo.AddressDetails,
              'Postal Code': this.offer.addressTo.PostCode,
              'Country To': this.getCountryDisplayNameByOid(this.offer.addressTo.Country),
              'Contact Person': this.offer.addressTo.ContactPerson,
              Email: this.offer.addressTo.Email,
              'Telephone Number': this.offer.addressTo.Telephone,
            }
            break
          default:
        }

        return data
      },
      getRules: getRules,
      // mono etsi mporw na tre3w ta parakatw functions pou eginan load apo to api-payment
      loadCheckoutScript: loadCheckoutScript,
    },
  }
</script>

<style lang="scss" scoped>
.checkout-content {
  max-width: 700px;

  .v-card {
    border-radius: 0;
    box-shadow: none;
  }

  .v-card__actions {
    padding: 0 30px 30px;
  }

  .order-summary {
    padding: 25px;
    background-color: #e7f2fb;
    .row{
      box-shadow: none;
      &.sections-row {
        margin-bottom: 20px;
        .col{
          padding: 0;
          background-color: #e7f2fb;
          margin-right: 15px;
          .v-card__title{
            font-size: 15px;
          }
          &:last-child {
            margin-right: 0;
            margin-left: 15px;
          }
          .summary-section {
            box-shadow: none;
            &.v-card {
              &.v-sheet{
                background-color: #e7f2fb;
                font-size: 14px;
                .v-card__title{
                  background-color: $goldairLightBlue2;
                  padding: 5px 10px;
                  font-weight: bold;
                  color: #fff;
                }
                .v-card__text{
                  padding: 18px 0;
                  .label {
                    color: $goldairBlue4;
                  }
                  .value {
                    color: $goldairBlue;
                  }
                }
              }
            }
          }
        }
      }
      &.price-wrapper {
        padding: 20px 0 0 0;
        text-align: right;
        .col{
          padding: 0;
          .v-card{
            padding: 0;
            box-shadow: none;
            background-color: #e7f2fb;
            .v-card__text {
              padding: 0;
              .label {
                color: $goldairBlue;
                font-size: 16px;
              }
              .price {
                color: $goldairYellow;
                font-size: 18px;
                // font-weight: bold;
              }
              .vat {
                color: $goldairYellow;
                font-size: 13px;
              }
            }
          }
        }
      }
      .price-wrapper:nth-child(3) > .price{
        font-weight: bold;
      }
    }
  }
}
</style>

<style lang="scss">
.v-tabs {
  margin-bottom: 10px;

  .v-tabs-bar {
    background-color: transparent !important;
    height: 37px;

    .v-tab {
      width: 50%;
      background-color: $bgWhite07;
      color: rgb(40, 82, 132) !important;
      border-radius: 35px;
      height: 37px;
      position: relative;
      margin: 0 2%;

      &:first-child {
        margin-left: 0 !important;
      }

      &:last-child {
        margin-right: 0;
      }

      &.v-tab--active {
        background-color: #fff;
        color: $goldairBlue !important;

        .step-num {
          position: absolute;
          left: 10px;
          color: $goldairBlue !important;
        }
      }

      .step-num {
        position: absolute;
        left: 10px;
        color: #fff !important;
      }
    }
  }
}
.step-title{
  .v-card__title {
    background-color: $goldairYellow;
    color: #fff;
    font-size: 18px;
    padding: 5px 20px;
    line-height: 37px;
  }
}
.payment-section{
  text-align: right;
  .col {
    padding-bottom: 0;
  }
  .select-payment-method-text{
    color: $goldairBlue;
    font-size: 15px;
    font-weight: bold;
  }
  .payment-methods-card {
    box-shadow: none !important;
    padding: 0 30px 0 0;

    .v-card__title {
      background-color: $goldairYellow;
      color: #fff;
      font-size: 18px;
      padding: 5px 20px;
      line-height: 37px;
    }

    .v-card__text {
      padding: 0;
    }
    .payment-methods-list {
      margin-top: 0;
      padding: 0;

      .v-radio {
        border: 1px solid $goldairLightBlue;
        padding: 10px 30px;
        border-radius: 5px;

        &.v-item--active {
          background-color: #deebf7;
        }

        .payment-method-row {
          max-height: 60px;
          width: 100%;
          display: table;
          color: $goldairBlue;

          .v-label {
            display: block;
          }

          .payment-method-title {
            display: table-cell;
            vertical-align: middle;
            width: 40%;
            font-size: 15px
          }

          .payment-method-description {
            display: table-cell;
            vertical-align: middle;
            width: 60%;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.accept-terms-row{
  text-align: right;
  .col {
    padding-top: 0;
    padding-bottom: 0;
    .accept-terms-and-conditions {
      .v-input--checkbox {
        margin-top: 0;
        min-height: 50px;

        .v-messages {
          width: max-content;
        }
      }

      .checkbox-text {
        color: $goldairLightBlue2 !important;
        font-size: 12px;

        .accept-text {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
